body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border-radius: 5px;
  border-style: solid;
  padding-left: 10px;
  height: 20px;
  border-color: #635251;
  margin: 15px;
}

.main {
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 'cover';
  background-repeat: 'no-repeat';
}

.scrollpic {
  position: fixed;
  top: 150px;
  width: 140px;
  left: 100px;
  border: white;
  border-style: solid;
  border-width: 10px;
  overflow: auto;
  height: 150px;
  -webkit-overflow-scrolling: touch;
}

.portal {
  position: fixed;
  width: 140px;
  border: white;
  border-style: solid;
  border-width: 10px;
  overflow: auto;
  height: 150px;
  -webkit-overflow-scrolling: touch;
}

.arrow {
  position: fixed;
  width: 30px;
}

.question {
  position: fixed;
  width: 25px;
}

.big {
  width: 1000px;
  height: 1000px;
  overflow: hidden;
}

.bigIMG {
  overflow: hidden;
}

.modal {
  position: fixed;
  background: white;
  opacity: 0.6;
  border-radius: 5px;
}

.header {
  position: fixed;
  width: 100%;
  height: 64px;
  background-color: transparent;
}

.closeButton {
  height: 15px;
  margin: 10px;
  cursor: pointer;
}

.blueButton {
  background-image: url('./assets/bluebutton.jpg');
  outline: none;
  border: hidden;
  cursor: pointer;
  height: 45px;
  width: 112px;
}

.rightHeaderButton {
  position: fixed;
  top: 9px;
  right: 112px;
}

.formSubmitButton {
  margin-left: 20px;
  margin-bottom: 20px;
  color: white;
  font-family: 'Cutive Mono', monospace;
  font-size: 100%;
}

.shadowButton:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.smallUserImgRight {
  position: fixed;
  width: 30px;
  top: 19px;
  right: 66px;
  height: 30px;
  cursor: pointer;
  z-index: 10;
}

.postImage {
  width: 50px;
  margin: 20px;
  align-self: center;
}

.posts {
  position: fixed;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  top: 100px;
  height: calc(100vh - 100px);
  width: calc(100vw - 350px);
  left: 350px;
  border: white;
  border-style: solid;
  border-width: 10px;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: local;
}
.post{
  display: flex;
}

.boilertext {
  width: calc(100% - 30px);
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  margin: auto;
  line-height: 1.3;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.small {
  font-size: small;
}

.monospace {
  font-family: 'Cutive Mono', monospace;
  font-size: 100%;
  background-color: #FFFF00;
  width: 90%;
}

.italics {
  font-style: italic;
}

.ptext {
  width: 60%;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  line-height: 1.3;
}

@media only screen and (max-width: 450px) {
  .scrollpic {
    position: static;
    margin: 100px calc((100vw - 140px)/2) 100px calc((100vw - 150px)/2);
  }
}

@media only screen and (max-width: 900px) {
  .scrollpic {
    top: 100px;
  }

  .posts {
    top: 350px;
    left: 0px;
    height: calc(100vh - 350px);
    width: 100vw;
  }

  .modal {
    width: 90% !important;
  }
}